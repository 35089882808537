import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useState } from 'react'
import { LayerProps, useLayer } from 'react-laag'

import { ResizeObserver } from '../../../../../utils/ResizeObserver'
import { Icon, IconName } from '../../../../DataDisplay/Icon/Icon'
import styles from './ToggleHeaderLink.module.scss'

type ToggleHeaderLinkProps = {
  title: string
  transparent?: boolean
}

export const ToggleHeaderLink: FC<ToggleHeaderLinkProps> = ({ children, title, transparent }) => {
  const [isOpen, setOpen] = useState(false)
  const { layerProps, triggerProps, renderLayer } = useLayer({
    isOpen,
    ResizeObserver,
    placement: 'top-start',
    auto: true,
    onOutsideClick: () => setOpen(false),
  })

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => setOpen((currentOpenState) => !currentOpenState)}
        className={classNames(styles.container, transparent && styles.transparent, isOpen && styles.isOpen)}
        type="button"
      >
        <Icon name={IconName.USER} size={16} className={classNames(styles.icon)} />
        <span className={styles.buttonLabel}>{title}</span>
      </button>
      {isOpen &&
        renderLayer(
          <div>
            <PopUpComponent layerProps={layerProps}>{children}</PopUpComponent>
          </div>
        )}
    </>
  )
}

const animate = { opacity: 1, scale: 1, x: 0, y: 0 }

const initialAnimationState = {
  opacity: 0,
  scale: 0.85,
}

const transition = {
  type: 'spring',
  stiffness: 800,
  damping: 35,
}

type PopUpComponentProps = {
  layerProps: LayerProps
}

const PopUpComponent: FC<PopUpComponentProps> = ({ children, layerProps }) => {
  const initial = {
    ...initialAnimationState,
  }
  return (
    <AnimatePresence>
      <motion.ul
        className={styles.menu}
        ref={layerProps.ref}
        style={layerProps.style}
        initial={initial} // animate from
        animate={animate} // animate to
        exit={initial} // animate exit
        transition={transition}
      >
        {children}
      </motion.ul>
    </AnimatePresence>
  )
}
