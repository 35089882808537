import { Icon, IconName } from 'components/DataDisplay/Icon/Icon'
import { useToast } from 'context/ToastContext'
import styles from './Toast.module.scss'

export type ToastProps = {
  tone: 'success' | 'error'
  message: string
  // eslint-disable-next-line react/no-unused-prop-types
  duration: number
}

export const Toast = ({ tone, message }: ToastProps) => {
  const { setToast } = useToast()

  const closeToast = () => {
    setToast(null)
  }
  return (
    <div className={`${styles.toastMsg} ${styles[tone]}`}>
      {message}
      <button type="submit" className={styles.iconContainer} onClick={closeToast}>
        <Icon size={16} name={IconName.CLOSE} className={styles.icon} />
      </button>
    </div>
  )
}
