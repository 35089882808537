import { useMe } from 'api/queries/fetchUserMe'
import React, { FC } from 'react'
import { AuthRoleType } from 'model/User'
import { HeaderDesktop } from './components/HeaderDesktop'
import { HeaderMobile } from './components/HeaderMobile'
import styles from './Header.module.scss'

type HeaderProps = {
  placeholder?: boolean
  transparent?: boolean
}

export const Header: FC<HeaderProps> = ({ placeholder = true, transparent = false }) => {
  // Create hooks
  const me = useMe()

  // Prepare a valid user name
  const userName = !me ? null : me.role === AuthRoleType.USER ? me.name : 'Admin'

  return (
    <>
      <HeaderMobile userName={userName} placeholder={placeholder} transparent={transparent} className={styles.headerMobile} />
      <HeaderDesktop userName={userName} placeholder={placeholder} transparent={transparent} className={styles.headerDesktop} />
    </>
  )
}
