import classNames from 'classnames'
import React, { FC } from 'react'

import { Typography } from '../../../../DataDisplay/Typography/Typography'
import { Link } from '../../../../Link/Link'
import styles from './HeaderDesktopLink.module.scss'

type HeaderDesktopLinkProps = {
  route: string
  transparent?: boolean
  className?: string
  newTarget?: boolean
  isImportant?: boolean
  locale?: string
  hasIcon?: boolean
}

export const HeaderDesktopLink: FC<HeaderDesktopLinkProps> = ({
  className,
  children,
  route,
  locale,
  transparent,
  newTarget,
  hasIcon = false,
  isImportant = false,
}) => {
  return (
    <Link
      route={route}
      locale={locale}
      className={classNames(
        className,
        styles.container,
        transparent && styles.transparent,
        isImportant && styles.isImportant,
        hasIcon && styles.hasIcon
      )}
      newTarget={newTarget}
    >
      <Typography className={styles.text}>{children}</Typography>
    </Link>
  )
}
