import Cookies from 'js-cookie'
import { getPublicRuntimeConfig } from '../services/config/publicRuntimeConfig'

export function cookieSetter(name: string, value: string, sameSite: boolean, secure: boolean) {
  const config = getPublicRuntimeConfig()

  const dev = config.environment === 'development'

  Cookies.set(name, value, { sameSite: sameSite ? 'Strict' : 'None', secure: dev ? false : secure })
}

export function setLocaleCookie(locale: 'en' | 'es') {
  Cookies.set('NEXT_LOCALE', locale)
}
