import { Surface } from 'utils/properties/common'
import { PropertyTag } from './PropertyTag'

export const PROPERTY_SEARCH_PARAM_KEY = 'lastSearch'

export type PropertySearchParams = {
  city: string | undefined
  roomsAmount: number[]
  lifestyleChoices: PropertyTag[]
  pricePerMonthRange: {
    minPricePerMonth?: number
    maxPricePerMonth?: number
  }
  neighborhoods: string[]
  bathroomsAmount: number[]
  surfaceRange: Surface
}

export function createDefaultSearch(): PropertySearchParams {
  return {
    bathroomsAmount: [],
    city: undefined,
    lifestyleChoices: [],
    neighborhoods: [],
    pricePerMonthRange: {
      minPricePerMonth: undefined,
      maxPricePerMonth: undefined,
    },
    roomsAmount: [],
    surfaceRange: {
      minSurface: undefined,
      maxSurface: undefined,
    },
  }
}

export function parseSearchParamsFromQueryParams(queryParams: Record<string, any>): PropertySearchParams | undefined {
  const {
    city,
    roomsAmount,
    pricePerMonthRange,
    neighborhoods,
    bathroomsAmount,
    minSurface,
    maxSurface,
    minPricePerMonth,
    maxPricePerMonth,
    lifestyles,
  } = queryParams

  if (Object.keys(queryParams).length <= 0) {
    return undefined
  }

  const bathroomsArray = bathroomsAmount ? bathroomsAmount.split(',').map((room: string) => Number(room)) : []

  const lifestyleChoices: PropertyTag[] = lifestyles ? lifestyles.split(',') : []

  const neighborhoodArray = neighborhoods ? neighborhoods.split(',') : []

  // Two ways of getting the price per month (search)
  const pricePerMonth = pricePerMonthRange
    ? {
        minPricePerMonth: Number(pricePerMonthRange.split('-')[0]) || undefined,
        maxPricePerMonth: Number(pricePerMonthRange.split('-')[1]) || undefined,
      }
    : {
        minPricePerMonth: Number(minPricePerMonth) || undefined,
        maxPricePerMonth: Number(maxPricePerMonth) || undefined,
      }

  const roomsArray = roomsAmount ? roomsAmount.split(',').map((room: string) => Number(room)) : []

  const surfaceRange = {
    minSurface: Number(minSurface) || undefined,
    maxSurface: Number(maxSurface) || undefined,
  }

  return {
    bathroomsAmount: bathroomsArray,
    city: city || undefined,
    lifestyleChoices,
    neighborhoods: neighborhoodArray,
    pricePerMonthRange: pricePerMonth,
    roomsAmount: roomsArray,
    surfaceRange,
  }
}

export function buildPropertySearchUrlParams(params: PropertySearchParams): string {
  const currentUrlParams = new URLSearchParams()

  if (params.city) {
    currentUrlParams.set('city', params.city)
  }

  if (params.neighborhoods?.length > 0) {
    currentUrlParams.set('neighborhoods', params.neighborhoods.toString())
  }

  if (params.lifestyleChoices?.length > 0) {
    currentUrlParams.set('lifestyles', params.lifestyleChoices.toString())
  }

  if (params.roomsAmount?.length > 0) {
    currentUrlParams.set('roomsAmount', params.roomsAmount.toString())
  }

  if (params.bathroomsAmount?.length > 0) {
    currentUrlParams.set('bathroomsAmount', params.bathroomsAmount.toString())
  }

  if (params.pricePerMonthRange.minPricePerMonth) {
    currentUrlParams.set('minPricePerMonth', params.pricePerMonthRange.minPricePerMonth.toString())
  }

  if (params.pricePerMonthRange.maxPricePerMonth) {
    currentUrlParams.set('maxPricePerMonth', params.pricePerMonthRange.maxPricePerMonth.toString())
  }

  if (params.surfaceRange.minSurface) {
    currentUrlParams.set('minSurface', params.surfaceRange.minSurface.toString())
  }

  if (params.surfaceRange.maxSurface) {
    currentUrlParams.set('maxSurface', params.surfaceRange.maxSurface.toString())
  }

  return currentUrlParams.toString()
}

export function savePropertySearchParams(params: PropertySearchParams) {
  if (window) {
    window.localStorage.setItem(PROPERTY_SEARCH_PARAM_KEY, JSON.stringify(params))
  }
}

export function deletePropertySearchParams() {
  window.localStorage.removeItem(PROPERTY_SEARCH_PARAM_KEY)
}

export function getPropertySearchParams(): PropertySearchParams | undefined {
  if (!window) {
    return undefined
  }

  const savedQuery = window.localStorage.getItem(PROPERTY_SEARCH_PARAM_KEY)

  if (!savedQuery) {
    return undefined
  }

  try {
    return JSON.parse(savedQuery) as PropertySearchParams
  } catch (e) {
    return undefined
  }
}
