import ResizeObserverPolyfill from 'resize-observer-polyfill'

/**
 * ResizeObserver with polyfill for Safari in iOS
 */
export const ResizeObserver =
  typeof window !== 'undefined' && 'ResizeObserver' in window
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ResizeObserver
    : ResizeObserverPolyfill
