import React, { FC } from 'react'
import classNames from 'classnames'
import { Icon, IconName } from '../../../../DataDisplay/Icon/Icon'
import { Typography } from '../../../../DataDisplay/Typography/Typography'
import styles from './HeaderLink.module.scss'
import { Link } from '../../../../Link/Link'

type HeaderLinkProps = {
  path: string
  iconName: IconName
  title: string
  useSSRRouting?: boolean
  className?: string
  disabled?: boolean
  onClick?: () => void
}

export const HeaderLink: FC<HeaderLinkProps> = ({ path, iconName, title, useSSRRouting, className, disabled, onClick }) => (
  <li className={classNames(className, styles.container, disabled && styles.disabled)}>
    <Link className={styles.link} route={path} disabled={disabled} useSSRRouting={useSSRRouting} onClick={onClick}>
      <Icon name={iconName} className={styles.icon} />
      <Typography className={styles.text}>{title}</Typography>
    </Link>
  </li>
)
