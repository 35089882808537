import { Text } from 'components/Text/Text'
import Image from 'next/image'
import styles from './Loading.module.scss'

export type LoadingProps = {
  message: string
  containerClassname?: string
}

export const Loading = ({ message, containerClassname = '' }: LoadingProps) => {
  return (
    <div className={`${styles.loadingContainer} ${containerClassname}`}>
      <Image src="/images/loader.gif" alt="Loading..." width={150} height={150} />
      <Text type="h2" weight="bold" align="center" color="blue-600">
        {message}
      </Text>
    </div>
  )
}
