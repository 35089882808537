import classNames from 'classnames'
import { Footer } from 'components/Footer/Footer'
import { Loading } from 'components/Loading/Loading'
import { Header } from 'components/Surfaces/Headers/Header/Header'
import { Toast } from 'components/Toast/Toast'
import { useLoading } from 'context/LoadingContext'
import { useToast } from 'context/ToastContext'
import { FC } from 'react'

import styles from './PageLayout.module.scss'

export type PageLayoutProps = {
  className?: string
  headerTransparent?: boolean
  footerClass?: string
  hideHeader?: boolean
  hideFooter?: boolean
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  className,
  footerClass,
  hideFooter = false,
  hideHeader = false,
  headerTransparent,
}) => {
  const { toast } = useToast()
  const { loading } = useLoading()
  const classNameContent = classNames(styles.content, {
    [styles['content--transparent']]: !!headerTransparent,
    [styles['content--no-header']]: !!hideHeader,
  })

  return (
    <div className={classNames(styles.containerFluid, className)}>
      {loading && <Loading {...loading} />}
      {toast && <Toast {...toast} />}
      {!hideHeader && <Header />}
      <div className={classNameContent}>{children}</div>
      {!hideFooter && <Footer className={footerClass} />}
    </div>
  )
}
